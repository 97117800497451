import { Box, HStack, Text } from "@chakra-ui/react";
import React from "react";
import NavButton from "../../../component/navButton/Index";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import UserTable from "./TableUser";

const User = () => {
  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={"10"}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontFamily={"Semibold"} color="black">
              User
            </Text>
          </Box>
          <NavButton nav="/user/add" title="Add User" />
        </HStack>

        <UserTable />
      </Box>
    </AnimatedContainer>
  );
};

export default User;
