import * as React from "react";

import TreeMenu from "react-simple-tree-menu";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { URL } from "../../../utils/appURL";
import { useHttp } from "../../../utils/http";

const MenuTable = ({ label }) => {
  const { get } = useHttp();
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const transformDataToTree = (data) => {
    return data.map((menu) => {
      let mainMenu = {
        key: menu.id,
        id: menu.id,
        label: menu.title,
        nodes: [],
      };

      if (menu.sub_menus && menu.sub_menus.length > 0) {
        mainMenu.nodes = menu.sub_menus.map((subMenu) => {
          return {
            key: subMenu.id,
            label: subMenu.title,
            id: subMenu.id,
          };
        });
      }

      return mainMenu;
    });
  };
  const treeDatas = transformDataToTree(data);
  const fetchData = (text) => {
    get({
      url: URL.getListMasterMenu,
      needAuth: true,
      showToast: false,
    }).then((response) => {
      setData(response.data.data);
    });
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <TreeMenu
      data={treeDatas}
      onClickItem={({ key, id, label, ...props }) => {
        navigate(`${id}`);
      }}
    />
  );
};

export default MenuTable;
