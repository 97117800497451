import {
  Box,
  Button,
  Center,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useParams } from "react-router-dom";
import ActivityLogs from "../../../component/activityLog/Index";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import MyLoader from "../../../component/loader/MyLoader";
import MyIcon from "../../../component/myIcon/Index";
import SalesLog from "../../../component/salesLog/Index";
import { FONTSIZES } from "../../../theme/FontSize";
import { URL } from "../../../utils/appURL";
import formatedDate from "../../../utils/formatedDate";
import { useHttp } from "../../../utils/http";
const DetailCustomer = () => {
  const { get } = useHttp();
  const productId = useParams();
  const [data, setdata] = useState({
    name: "",
    category: "",
    code: "",
    activity_logs: [],
    sales: [],
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [transactionLog, settransactionLog] = useState(false);
  const [isLoading, setisLoading] = useState(true);

  const fetchData = () => {
    get({
      url: URL.getListCustomerURL + `/${productId.id}`,
      needAuth: true,
      showToast: false,
    }).then((response) => {
      setdata(response.data.data);

      setisLoading(false);
    });
  };
  useEffect(() => {
    fetchData();
  }, [productId]);
  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={10}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontFamily={"Semibold"} color="black">
              Customer
            </Text>
          </Box>
          <HStack>
            <Button colorScheme="blue" onClick={() => settransactionLog(true)}>
              <HStack>
                <MyIcon name={"notepad-text"} size={14} />
                <Text
                  fontSize={FONTSIZES.md}
                  fontFamily={"SemiBold"}
                  color="white"
                >
                  History Transaction
                </Text>
              </HStack>
            </Button>
            <Button colorScheme="teal" onClick={onOpen}>
              <HStack>
                <MyIcon name={"notepad-text"} size={14} />
                <Text
                  fontSize={FONTSIZES.md}
                  fontFamily={"SemiBold"}
                  color="white"
                >
                  Show Log
                </Text>
              </HStack>
            </Button>
          </HStack>
        </HStack>
        {isLoading ? (
          <MyLoader />
        ) : (
          <Box borderWidth={1} borderRadius={5} p={5}>
            <Text fontFamily={"Semibold"}>Detail Customer</Text>
            <Box borderRadius={5} p={5}>
              <HStack spacing={10} flex={1} alignItems={"flex-start"}>
                <Stack flex={3} py={4} spacing={5}>
                  <HStack>
                    <HStack flex={1} spacing={2} alignItems={"center"}>
                      <Box flex={0.1}>
                        <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                          Name
                        </Text>
                      </Box>
                      <Box flex={0.8}>
                        <Input
                          fontSize={FONTSIZES.md}
                          readOnly
                          fontFamily={"Medium"}
                          value={data.name}
                        />
                      </Box>
                    </HStack>
                  </HStack>
                  <HStack flex={1} spacing={2} alignItems={"center"}>
                    <Box flex={0.1}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        Code
                      </Text>
                    </Box>
                    <Box flex={0.8}>
                      <Input
                        fontSize={FONTSIZES.md}
                        readOnly
                        fontFamily={"Medium"}
                        defaultValue={data.code}
                      />
                    </Box>
                  </HStack>
                  <HStack flex={1} spacing={2} alignItems={"center"}>
                    <Box flex={0.1}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        Type
                      </Text>
                    </Box>
                    <Box flex={0.8}>
                      <Input
                        fontSize={FONTSIZES.md}
                        readOnly
                        fontFamily={"Medium"}
                        defaultValue={data.type}
                      />
                    </Box>
                  </HStack>
                  <HStack flex={1} spacing={2} alignItems={"center"}>
                    <Box flex={0.1}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        Store
                      </Text>
                    </Box>
                    <Box flex={0.8}>
                      <Input
                        fontSize={FONTSIZES.md}
                        readOnly
                        fontFamily={"Medium"}
                        defaultValue={data.store?.name}
                      />
                    </Box>
                  </HStack>
                </Stack>
                <Stack flex={3} py={4} spacing={5}>
                  <HStack flex={1} spacing={2} alignItems={"center"}>
                    <Box flex={0.1}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        Address
                      </Text>
                    </Box>
                    <Box flex={0.8}>
                      <Input
                        fontSize={FONTSIZES.md}
                        readOnly
                        fontFamily={"Medium"}
                        defaultValue={data.address}
                      />
                    </Box>
                  </HStack>
                  <HStack>
                    <HStack flex={1} spacing={2} alignItems={"center"}>
                      <Box flex={0.1}>
                        <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                          Phone
                        </Text>
                      </Box>
                      <Box flex={0.8}>
                        <Input
                          fontSize={FONTSIZES.md}
                          readOnly
                          fontFamily={"Medium"}
                          value={data.phone_number}
                        />
                      </Box>
                    </HStack>
                  </HStack>
                  <HStack>
                    <HStack flex={1} spacing={2} alignItems={"center"}>
                      <Box flex={0.1}>
                        <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                          Email
                        </Text>
                      </Box>
                      <Box flex={0.8}>
                        <Input
                          fontSize={FONTSIZES.md}
                          readOnly
                          fontFamily={"Medium"}
                          value={data.email}
                        />
                      </Box>
                    </HStack>
                  </HStack>
                  <HStack>
                    <HStack flex={1} spacing={2} alignItems={"center"}>
                      <Box flex={0.1}>
                        <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                          Date
                        </Text>
                      </Box>
                      <Box flex={0.8}>
                        <Input
                          fontSize={FONTSIZES.md}
                          readOnly
                          fontFamily={"Medium"}
                          value={formatedDate(data.created_at)}
                        />
                      </Box>
                    </HStack>
                  </HStack>
                  <HStack>
                    <HStack flex={1} spacing={2} alignItems={"center"}>
                      <Box flex={0.1}>
                        <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                          Status
                        </Text>
                      </Box>
                      <Box flex={0.8}>
                        <Switch
                          size="md"
                          isChecked={data.is_active == 1 ? true : false}
                        />
                      </Box>
                    </HStack>
                  </HStack>
                </Stack>
              </HStack>
            </Box>
          </Box>
        )}
      </Box>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={["xs", "sm", "md", "lg", "xl"]}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              Data log customer : {data.code}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {data.activity_logs.map((item) => {
              return <ActivityLogs item={item} />;
            })}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      {/*  */}
      <Modal
        isOpen={transactionLog}
        onClose={() => settransactionLog(false)}
        size={"xxl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              History transaction customer : {data.code}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {data.sales.length > 0 ? (
              data.sales.map((item) => {
                return <SalesLog item={item} />;
              })
            ) : (
              <Stack mt={5}>
                <Center>
                  <Text fontFamily={"Semibold"}>Data Not Found</Text>
                </Center>
                <Lottie
                  options={{
                    animationData: require("../../../assets/lotties/empty.json"),
                  }}
                  width={"20vw"}
                />
              </Stack>
            )}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </AnimatedContainer>
  );
};
export default DetailCustomer;
