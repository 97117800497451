import {
  Box,
  Button,
  Center,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Switch,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useParams } from "react-router-dom";
import ICChevronLeft from "../../../assets/icons/ICChevronLeft";
import ICChevronRight from "../../../assets/icons/ICChevronRight";
import ActivityLogs from "../../../component/activityLog/Index";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import MyLoader from "../../../component/loader/MyLoader";
import MyIcon from "../../../component/myIcon/Index";
import { FONTSIZES } from "../../../theme/FontSize";
import { URL } from "../../../utils/appURL";
import formatedDate from "../../../utils/formatedDate";
import { useHttp } from "../../../utils/http";
import RowStore from "./RowStore";
const DetailStore = () => {
  const { get, put } = useHttp();
  const parameter = useParams();
  const productId = parameter?.id;
  const [data, setdata] = useState({
    name: "",
    category: "",
    code: "",
    activity_logs: [],
    persons: [{ person_access: { roles: [{ name: "" }] } }],
    inventory: [{ product: { name: "", unit: { name: "" } } }],
  });

  const [isActive, setisActive] = useState(false);
  const [showLog, setshowLog] = useState(false);

  const [isDisable, setisDisable] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [isLoading, setisLoading] = useState(true);
  const [inventory, setinventory] = useState([]);
  const [modalPagination, setmodalPagination] = useState({
    usePagination: false,
    currentPage: 1,
    totalPage: 1,
  });
  const [isLoadingModal, setisLoadingModal] = useState(true);

  const handleActive = (e) => {
    setisActive(!isActive);
    setdata((prevData) => ({
      ...prevData,
      isActive: e.target.value,
    }));
  };

  const handleEdit = () => {
    put({
      url: URL.getListStoreURL + `/update/${productId.id}`,
      data: {
        name: data.name,
        code: data.code,
        address: data.address,
        is_active: isActive,
      },
      needAuth: true,
    })
      .then((res) => {
        toast({
          title: "Success",
          description: JSON.stringify(res.data.message),
          status: "success",
          duration: 2000,
          position: "top",
        });
        setisDisable(true);
      })
      .catch((err) => {});
  };

  const getExport = async () => {
    setisLoadingModal(true);
    let currentPage = 0;
    let totalPage = 1;
    let data = [];
    let looping = true;
    while (looping) {
      currentPage++;
      await get({
        url:
          URL.getListStoreURL + `/inventory/${productId}?page=${currentPage}`,
        needAuth: true,
        showToast: false,
      })
        .then((response) => {
          totalPage = response?.data?.data?.last_page;
          const newData = response?.data?.data?.data;
          data.push(...newData);
          if (currentPage === totalPage) {
            looping = false;
          }
        })
        .catch((error) => {
          looping = false;
        });
    }
    setisLoadingModal(false);
    return data;
  };
  const getInventory = async (page = 1) => {
    let dataInventory = [];

    get({
      url: URL.getListStoreURL + `/inventory/${productId}?page=${page}`,
      needAuth: true,
      showToast: false,
    })
      .then((response) => {
        const totalPage = response?.data?.data?.last_page;
        const newData = response?.data?.data?.data;
        if (totalPage) {
          setmodalPagination({
            usePagination: true,
            currentPage: page,
            totalPage: totalPage,
          });
        }
        setinventory(newData);
        setisLoadingModal(false);
      })
      .catch((error) => {
        setisLoadingModal(false);
      });

    setisLoadingModal(false);
  };
  const handleInventory = () => {
    setisLoadingModal(true);
    getInventory();
    onOpen();
  };

  const generateExcel = async (params) => {
    const wb = new ExcelJS.Workbook();

    const ws = wb.addWorksheet();

    ws.getColumn(1).width = 30;
    ws.getColumn(2).width = 30;
    ws.getColumn(3).width = 30;
    ws.getColumn(4).width = 30;
    ws.getColumn(5).width = 30;
    ws.getColumn(6).width = 30;
    ws.getColumn(7).width = 30;
    ws.getColumn(8).width = 30;
    ws.getColumn(9).width = 30;
    ws.getColumn(10).width = 30;

    const rowHeader = ws.getRow(1);

    for (let i = 1; i <= 10; i++) {
      rowHeader.getCell(i).border = {
        top: { style: "Regular" },
        left: { style: "Regular" },
        bottom: { style: "Regular" },
        right: { style: "Regular" },
      };

      rowHeader.getCell(i).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: "white",
      };

      rowHeader.getCell(i).alignment = {
        vertical: "middle",
        horizontal: "center",
      };

      rowHeader.getCell(i).font = {
        bold: true,
        size: 11,
        color: "black",
      };
    }

    rowHeader.getCell(1).value = "Store Code";
    rowHeader.getCell(2).value = "Store Name";
    rowHeader.getCell(3).value = "Store Address";
    rowHeader.getCell(4).value = "Product Code";
    rowHeader.getCell(5).value = "Product Name";
    rowHeader.getCell(6).value = "Category Code";
    rowHeader.getCell(7).value = "Category Name";
    rowHeader.getCell(8).value = "Transaction Date";
    rowHeader.getCell(9).value = "Description";
    rowHeader.getCell(10).value = "End Stock";

    let no = 1;
    for (let i = 1; i <= params.length; i++) {
      const row = ws.getRow(i + 1);
      row.getCell(1).value = data.code;
      row.getCell(2).value = data.name;
      row.getCell(3).value = data.address;
      row.getCell(4).value = params[i - 1].product?.code;
      row.getCell(5).value = params[i - 1].product.name;
      row.getCell(6).value = params[i - 1].product?.product_category?.code;
      row.getCell(7).value = params[i - 1].product?.product_category?.name;
      row.getCell(8).value = params[i - 1].created_at;
      row.getCell(9).value = params[i - 1].description;
      row.getCell(10).value = params[i - 1].end_stock;

      no++;
    }

    const buf = await wb.xlsx.writeBuffer();

    saveAs(
      new Blob([buf]),
      `Report Inventory - ${data.name} - ${formatedDate(new Date())}.xlsx`
    );
  };
  const exportToExcel = async () => {
    getExport().then((data) => {
      generateExcel(data);
    });
  };

  const handlePagination = (page) => {
    if (page < 1) {
      page = 1;
    }
    if (page > modalPagination.totalPage) {
      page = modalPagination.totalPage;
    }
    getInventory(page);
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const delta = 3;
    const left = modalPagination.currentPage - delta;
    const right = modalPagination.currentPage + delta + 1;

    for (let i = 1; i <= modalPagination.totalPage; i++) {
      if (
        i === 1 ||
        i === modalPagination.totalPage ||
        (i >= left && i < right)
      ) {
        pageNumbers.push(
          <Button
            size={["xs", "md"]}
            key={i}
            colorScheme="teal"
            variant={modalPagination.currentPage === i ? "solid" : "ghost"}
            isDisabled={
              modalPagination.currentPage === i || isLoadingModal === true
            }
            onClick={() => handlePagination(i)}
          >
            <Text fontFamily="Semibold" fontSize={FONTSIZES.md}>
              {i}
            </Text>
          </Button>
        );
      } else if (i === left - 1 || i === right + 1) {
        pageNumbers.push(
          <Button
            size={["xs", "md"]}
            key={i}
            colorScheme="teal"
            variant="ghost"
            disabled
          >
            <Text fontFamily="Semibold" fontSize={FONTSIZES.md}>
              ...
            </Text>
          </Button>
        );
      }
    }
    return pageNumbers;
  };
  const PaginationNav = () => {
    return (
      <HStack justify="flex-end" spacing={1}>
        {modalPagination.totalPage > 1 && (
          <>
            <Button
              size={["xs", "md"]}
              key={"first"}
              colorScheme="teal"
              variant={modalPagination.currentPage === 1 ? "solid" : "ghost"}
              isDisabled={
                modalPagination.currentPage === 1 || isLoadingModal === true
              }
              onClick={() => handlePagination(1)}
            >
              <Text fontFamily="Semibold" fontSize={FONTSIZES.md}>
                {"First"}
              </Text>
            </Button>
            <IconButton
              size={["xs", "md"]}
              aria-label="previous page"
              icon={<ICChevronLeft color="black" size="18" />}
              colorScheme="teal"
              variant="ghost"
              isDisabled={
                modalPagination.currentPage === 1 || isLoadingModal === true
              }
              onClick={() => handlePagination(modalPagination.currentPage - 1)}
            />
          </>
        )}
        {renderPageNumbers()}
        {modalPagination.totalPage > 1 && (
          <>
            <IconButton
              size={["xs", "md"]}
              aria-label="next page"
              icon={<ICChevronRight color="black" size="18" />}
              colorScheme="teal"
              variant="ghost"
              isDisabled={
                modalPagination.currentPage === modalPagination.totalPage ||
                isLoadingModal === true
              }
              onClick={() => handlePagination(modalPagination.currentPage + 1)}
            />
            <Button
              size={["xs", "md"]}
              key={"last"}
              colorScheme="teal"
              variant={
                modalPagination.currentPage === modalPagination.totalPage
                  ? "solid"
                  : "ghost"
              }
              isDisabled={
                modalPagination.currentPage === modalPagination.totalPage ||
                isLoadingModal === true
              }
              onClick={() => handlePagination(modalPagination.totalPage)}
            >
              <Text fontFamily="Semibold" fontSize={FONTSIZES.md}>
                {"Last"}
              </Text>
            </Button>
          </>
        )}
      </HStack>
    );
  };

  const fetchData = () => {
    get({
      url: URL.getListStoreURL + `/${productId}`,
      needAuth: true,
      showToast: false,
    })
      .then((res) => {
        setdata(res.data.data);

        setisActive(res.data.data.is_active);
        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
      });
  };
  useEffect(() => {
    fetchData();
  }, [productId]);
  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={10}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontFamily={"Semibold"} color="black">
              Store
            </Text>
          </Box>
          <HStack>
            <Button colorScheme="teal" onClick={() => setshowLog(true)}>
              <HStack>
                <MyIcon name={"notepad-text"} size={14} />
                <Text
                  fontSize={FONTSIZES.md}
                  fontFamily={"SemiBold"}
                  color="white"
                >
                  Show Log
                </Text>
              </HStack>
            </Button>
            <Button
              colorScheme="blue"
              minW={"100px"}
              bg={"blue.500"}
              onClick={handleInventory}
            >
              <HStack alignItems={"center"}>
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={[10, 12, 14]}
                >
                  Inventory
                </Text>
              </HStack>
            </Button>
            {/* <Button
              colorScheme="red"
              minW={"100px"}
              bg={"red.500"}
              onClick={onOpen}
            >
              <HStack alignItems={"center"}>
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={[10, 12, 14]}
                >
                  Delete
                </Text>
              </HStack>
            </Button> */}
            {/* <NavButton nav={-1} title="Back" /> */}
          </HStack>
        </HStack>
        {isLoading ? (
          <MyLoader />
        ) : (
          <Box borderWidth={1} borderRadius={5} p={5}>
            <Text fontFamily={"Semibold"}>Detail Store</Text>
            <HStack flex={1}>
              <Stack flex={3} py={4} spacing={5}>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Name</Text>
                  </Box>
                  <Box flex={0.7}>
                    <Input
                      readOnly={isDisable}
                      fontFamily={"Regular"}
                      defaultValue={data.name}
                      onChange={(e) =>
                        setdata((prevData) => ({
                          ...prevData,
                          name: e.target.value,
                        }))
                      }
                    />
                  </Box>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Code</Text>
                  </Box>
                  <Box flex={0.7}>
                    <Input
                      disabled={true}
                      fontFamily={"Regular"}
                      defaultValue={data.code}
                    />
                  </Box>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Address</Text>
                  </Box>
                  <Box flex={0.7}>
                    <Input
                      onChange={(e) =>
                        setdata((prevData) => ({
                          ...prevData,
                          address: e.target.value,
                        }))
                      }
                      readOnly={isDisable}
                      fontFamily={"Regular"}
                      defaultValue={data.address}
                    />
                  </Box>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Active</Text>
                  </Box>
                  <Box flex={0.7}>
                    <Switch
                      readOnly={isDisable}
                      onChange={handleActive}
                      isChecked={isActive == 1 ? true : false}
                    />
                  </Box>
                </HStack>
              </Stack>
            </HStack>
          </Box>
        )}
        {/* <Text>{JSON.stringify(inventory)}</Text> */}
        {isDisable !== true && (
          <HStack py={10} justifyContent={"flex-end"}>
            <Button
              onClick={handleEdit}
              colorScheme="teal"
              minW={"100px"}
              bg={"teal.500"}
            >
              <HStack alignItems={"center"}>
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={[10, 12, 14]}
                >
                  Save
                </Text>
              </HStack>
            </Button>
          </HStack>
        )}
      </Box>
      {isLoading ? (
        <MyLoader />
      ) : (
        <Box my="5" flex={1} bg="white" p="4" rounded={10}>
          <HStack justifyContent={"space-between"} mb={5}>
            <Box>
              <Text fontSize={FONTSIZES.md} fontFamily={"Medium"} color="black">
                List Mitra
              </Text>
            </Box>
          </HStack>
          <Box bg="teal.500" borderTopRadius={5} p={5}>
            <HStack flex={1}>
              <HStack flex={1} alignItems={"center"}>
                <Box flex={1}>
                  <Text
                    fontSize={FONTSIZES.md}
                    textAlign={"center"}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    Name
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    fontSize={FONTSIZES.md}
                    textAlign={"center"}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    Code
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    fontSize={FONTSIZES.md}
                    textAlign={"center"}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    Role
                  </Text>
                </Box>
              </HStack>
            </HStack>
          </Box>
          <Box borderBottomRadius={5}>
            {data.persons.map((item, index) => {
              return <RowStore item={item} key={index} />;
            })}
          </Box>
        </Box>
      )}
      <Modal isOpen={showLog} onClose={() => setshowLog(false)} size={["xl"]}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              Data log store : {data.code}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {data.activity_logs.map((item, index) => {
              return <ActivityLogs key={index} item={item} />;
            })}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpen} onClose={onClose} size={"xxl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <VStack alignItems={"left"}>
              <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
                Inventory Store {data.code}
              </Text>
              <HStack>
                <Button
                  rounded={5}
                  bg={"blue.500"}
                  size={"sm"}
                  onClick={exportToExcel}
                >
                  <Text
                    color="white"
                    fontFamily={"Regular"}
                    fontSize={FONTSIZES.md}
                  >
                    Export
                  </Text>
                </Button>
              </HStack>
            </VStack>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoadingModal ? (
              <Center>
                <Spinner mt={5} color="teal.500" />
              </Center>
            ) : (
              <Box flex={1} bg="white" pb="4" rounded={10}>
                <Box bg="teal.500" borderTopRadius={5} p={2}>
                  <HStack flex={1}>
                    <HStack flex={1} alignItems={"center"}>
                      <Box flex={1}>
                        <Text
                          fontSize={FONTSIZES.md}
                          textAlign={"center"}
                          color={"white"}
                          fontFamily={"SemiBold"}
                        >
                          Product
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text
                          fontSize={FONTSIZES.md}
                          textAlign={"center"}
                          color={"white"}
                          fontFamily={"SemiBold"}
                        >
                          Category
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text
                          fontSize={FONTSIZES.md}
                          textAlign={"center"}
                          color={"white"}
                          fontFamily={"SemiBold"}
                        >
                          Stock
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text
                          fontSize={FONTSIZES.md}
                          textAlign={"center"}
                          color={"white"}
                          fontFamily={"SemiBold"}
                        >
                          Unit
                        </Text>
                      </Box>
                      <Box flex={1}>
                        <Text
                          fontSize={FONTSIZES.md}
                          textAlign={"center"}
                          color={"white"}
                          fontFamily={"SemiBold"}
                        >
                          Last Update
                        </Text>
                      </Box>
                    </HStack>
                  </HStack>
                </Box>
                {inventory.length > 0 ? (
                  <Box borderBottomRadius={5}>
                    {inventory.map((item, index) => {
                      return (
                        <HStack
                          onClick={() => {}}
                          p="2"
                          bg={index % 2 !== 0 ? "teal.50" : "grey.50"}
                          flex={1}
                          alignItems={"center"}
                        >
                          <Box flex={1} alignItems={"center"}>
                            <Text
                              textAlign="center"
                              fontFamily={"Regular"}
                              fontSize={FONTSIZES.md}
                            >
                              {item.product?.name}
                            </Text>
                          </Box>
                          <Box flex={1} alignItems={"center"}>
                            <Text
                              textAlign="center"
                              fontFamily={"Regular"}
                              fontSize={FONTSIZES.md}
                            >
                              {item.product?.product_category?.name}
                            </Text>
                          </Box>

                          <Box flex={1}>
                            <Text
                              textAlign="center"
                              fontSize={FONTSIZES.md}
                              fontFamily={"Regular"}
                            >
                              {item.end_stock}
                            </Text>
                          </Box>
                          <Box flex={1}>
                            <Text
                              textAlign="center"
                              fontSize={FONTSIZES.md}
                              fontFamily={"Regular"}
                            >
                              {item.product?.unit.name}
                            </Text>
                          </Box>

                          <Box flex={1}>
                            <Text
                              textAlign="center"
                              fontSize={FONTSIZES.md}
                              fontFamily={"Regular"}
                            >
                              {item.created_at}
                            </Text>
                          </Box>
                        </HStack>
                      );
                    })}
                  </Box>
                ) : (
                  <Stack mt={5}>
                    <Center>
                      <Text fontFamily={"Semibold"}>Data Not Found</Text>
                    </Center>
                    <Lottie
                      options={{
                        animationData: require("../../../assets/lotties/empty.json"),
                      }}
                      width={"20vw"}
                    />
                  </Stack>
                )}
              </Box>
            )}
          </ModalBody>
          <ModalFooter>
            <PaginationNav />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </AnimatedContainer>
  );
};
export default DetailStore;
