import { Box, Button, HStack, Stack, Text } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React, { useEffect, useState } from "react";
import NavButton from "../../../component/navButton/Index";
import { URL } from "../../../utils/appURL";
import { useHttp } from "../../../utils/http";

const AssignPermission = () => {
  const { get, post } = useHttp();
  const [dataRole, setdataRole] = useState([]);
  const [dataPermission, setdataPermission] = useState([]);
  const [selectedPermission, setSelectedPermission] = useState([]);
  const [selectedRole, setSelectedRole] = useState({ id: 0, name: "" });
  const handleAdd = () => {
    const modifiedData = selectedPermission.map((item) => {
      return item.value;
    });

    post({
      url: URL.getListRoleURL + "/assign-role-to-permissions",
      data: {
        id: selectedRole.id,
        permissions: modifiedData,
      },
      needAuth: true,
      showToast: true,
    }).then((res) => {
      setSelectedRole([]);
      setdataRole([]);
      setdataPermission([]);
      setSelectedPermission([]);
    });
  };
  const fetchData = () => {
    get({
      url: URL.getListRoleURL,
      needAuth: true,
      showToast: false,
    }).then((response) => {
      setdataRole(response.data.data.data);
    });

    get({
      url: URL.getListPermissionURL,
      needAuth: true,
      showToast: false,
    }).then((response) => {
      setdataPermission(response.data.data);
    });
  };
  const handleChangeRole = (e) => {
    const id = e.value;
    setSelectedRole({ id: e.value, name: e.label });
    get({
      url: URL.getListRoleURL + `/${id}`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      const dataRes = res.data.data;
      const datPermis = dataRes.permissions;
      if (datPermis.length > 0) {
        const modifiedData = datPermis.map((item) => {
          return { value: item.id, label: item.name };
        });
        setSelectedPermission(modifiedData);
      }
    });
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Box flex={1} bg="white" p="4" rounded={10}>
      <HStack justifyContent={"space-between"} mb={5}>
        <Box>
          <Text fontFamily={"Semibold"} color="black">
            Assign Permission
          </Text>
        </Box>
        <NavButton nav={-1} title="Back" />
      </HStack>
      <Box borderWidth={1} borderRadius={5} p={5}>
        <Stack spacing={8} maxW={"lg"} py={4}>
          <HStack spacing={4} alignItems={"center"}>
            <Box flex={0.2}>
              <Text>Role</Text>
            </Box>
            <Box flex={1}>
              <Select
                value={{
                  value: selectedRole.id,
                  label: selectedRole.name,
                }}
                onChange={handleChangeRole}
                w={500}
                tagVariant="solid"
                options={dataRole.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </Box>
          </HStack>
          <HStack flex={1} spacing={4} alignItems={"center"}>
            <Box flex={0.2}>
              <Text>Permission</Text>
            </Box>
            <Box flex={1}>
              <Select
                chakraStyles={{
                  container: (provided) => ({
                    ...provided,
                    minWidth: "50vw",
                    fontSize: "2px",
                  }),
                }}
                isMulti
                value={selectedPermission}
                onChange={(e) => setSelectedPermission(e)}
                tagVariant="solid"
                options={dataPermission.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </Box>
          </HStack>
        </Stack>
      </Box>
      <HStack spacing={4} py={4} justifyContent={"flex-end"}>
        <Button
          isDisabled={
            selectedPermission.length === 0 || selectedRole.name === ""
          }
          onClick={handleAdd}
          colorScheme="teal"
          minW="100px"
        >
          <Text fontFamily={"Medium"} color="white" fontSize={[10, 12, 14]}>
            Save
          </Text>
        </Button>
      </HStack>
    </Box>
  );
};
export default AssignPermission;
