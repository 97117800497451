import {
  Box,
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { AsyncSelect } from "chakra-react-select";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ActivityLogs from "../../../component/activityLog/Index";
import MyIcon from "../../../component/myIcon/Index";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import MyLoader from "../../../component/loader/MyLoader";
import { FONTSIZES } from "../../../theme/FontSize";
import { URL } from "../../../utils/appURL";
import { formattedCurrency } from "../../../utils/formatedCurrency";
import formatedDate from "../../../utils/formatedDate";
import { useHttp } from "../../../utils/http";
import DataComponent from "./DataComponent";
const DetailPaymentBill = () => {
  const { get, post, deleting, put } = useHttp();
  const navigate = useNavigate();
  const productId = useParams();
  const [data, setdata] = useState({
    name: "",
    category: "",
    code: "",
    total: 0,
    store: {
      name: "",
      code: "",
      address: "",
    },
    sales_details: [],
    activity_logs: [],
    payment_method: { name: "" },
    transaction_date: "",
  });
  const [showDelete, setshowDelete] = useState(false);
  const [showEdit, setshowEdit] = useState(false);

  const [dataPayment, setdataPayment] = useState([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setisLoading] = useState(true);
  const [prosesLoading, setprosesLoading] = useState(false);
  const [maxPay, setmaxPay] = useState([]);

  const fetchDataPayment = async (input = "") => {
    let allData = [];
    let currentPage = 1;
    await get({
      url:
        URL.getListPaymentMethod +
        `?search=${input}&page=${currentPage}&with_paylater=0`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      allData = res.data.data.data;
    });
    return allData;
  };
  const fetchData = () => {
    get({
      url: URL.getPaymentBillURL + "/" + productId.id,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      setdata(res.data.data);

      setmaxPay(
        res.data.data.payment_details.map(
          (item) => item.pay + item.sales_header.total_remaining
        )
      );
      setdataPayment(res.data.data.payment_details);

      setisLoading(false);
    });
  };

  const handleDelete = () => {
    deleting({
      url: URL.getPaymentBillURL + "/delete/" + productId.id,
      needAuth: true,
      showToast: true,
    }).then((res) => {
      onClose();
      navigate(-1);
    });
  };
  const loadOptionsPaymentMethod = (inputValue) => {
    return fetchDataPayment(inputValue);
  };
  const handleSubmit = () => {
    setshowEdit(!showEdit);
    setprosesLoading(true);
    const newDataPayment = dataPayment.map((item) => ({
      payment_detail_id: item.id,
      sales_header_id: item.sales_header.id,
      bill: item.bill,
      pay: item.pay,
      notes: item.notes,
    }));
    const param = {
      store_id: data.store.id,
      person_id: data.person_id,
      payment_method_id: data.payment_method.id,
      payment_date: new Date(),
      notes: data.notes,
      details: newDataPayment,
    };
    put({
      url: URL.getPaymentBillURL + "/update/" + productId.id,
      data: param,
      needAuth: true,
      showToast: true,
    }).then((res) => {
      setprosesLoading(false);
    });
  };

  const handleDeleteRow = (id) => {
    setprosesLoading(true);
    deleting({
      url: URL.getPaymentBillURL + "/delete-line/" + id + "/" + productId.id,
      needAuth: true,
      showToast: true,
    }).then((res) => {
      setprosesLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
    fetchDataPayment();
  }, [showEdit, prosesLoading]);

  return (
    <AnimatedContainer>
      {prosesLoading ? (
        <Box
          justifyContent={"center"}
          alignItems={"center"}
          w="100vw"
          h="100%"
          position={"absolute"}
        >
          <MyLoader />
        </Box>
      ) : (
        <>
          <Box flex={1} bg="white" p="4" rounded={10}>
            <HStack justifyContent={"space-between"} mb={5}>
              <Box>
                <Text
                  fontSize={FONTSIZES.md}
                  fontFamily={"SemiBold"}
                  color="black"
                >
                  Payment Bill {data.transaction_no}
                </Text>
              </Box>
              <HStack spacing={2}>
                <Button colorScheme="teal" onClick={onOpen}>
                  <HStack>
                    <MyIcon name={"notepad-text"} size={14} />
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"SemiBold"}
                      color="white"
                    >
                      Show Log
                    </Text>
                  </HStack>
                </Button>
                <Button
                  colorScheme={showEdit === true ? "gray" : "blue"}
                  onClick={() => setshowEdit(!showEdit)}
                >
                  <HStack>
                    <MyIcon name={"pencil"} size={14} />
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"SemiBold"}
                      color={showEdit === true ? "black" : "white"}
                    >
                      {showEdit === true ? "Cancel Edit" : "Edit Payment Bill"}
                    </Text>
                  </HStack>
                </Button>
                <Button colorScheme="red" onClick={() => setshowDelete(true)}>
                  <HStack>
                    <MyIcon name={"circle-x"} size={14} />
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"SemiBold"}
                      color="white"
                    >
                      Delete All
                    </Text>
                  </HStack>
                </Button>
              </HStack>
            </HStack>
            {isLoading ? (
              <MyLoader />
            ) : (
              <Box borderWidth={1} borderRadius={5} p={5}>
                <HStack spacing={10} flex={1} alignItems={"flex-start"}>
                  <Stack flex={3} py={4} spacing={5}>
                    <HStack>
                      <HStack flex={1} spacing={2} alignItems={"center"}>
                        <Box flex={0.1}>
                          <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                            Store
                          </Text>
                        </Box>
                        <Box flex={0.8}>
                          <Input
                            fontSize={FONTSIZES.md}
                            readOnly
                            fontFamily={"SemiBold"}
                            value={data.store.name}
                          />
                        </Box>
                      </HStack>
                    </HStack>
                    <HStack flex={1} spacing={2} alignItems={"center"}>
                      <Box flex={0.1}>
                        <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                          Code
                        </Text>
                      </Box>
                      <Box flex={0.8}>
                        <Input
                          fontSize={FONTSIZES.md}
                          readOnly
                          fontFamily={"SemiBold"}
                          defaultValue={data.store.code}
                        />
                      </Box>
                    </HStack>
                    <HStack flex={1} spacing={2} alignItems={"center"}>
                      <Box flex={0.1}>
                        <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                          Address
                        </Text>
                      </Box>
                      <Box flex={0.8}>
                        <Input
                          fontSize={FONTSIZES.md}
                          readOnly
                          fontFamily={"SemiBold"}
                          defaultValue={data.store.address}
                        />
                      </Box>
                    </HStack>
                  </Stack>
                  <Stack flex={3} py={4} spacing={5}>
                    <HStack>
                      <HStack flex={1} spacing={2} alignItems={"center"}>
                        <Box flex={0.1}>
                          <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                            Date
                          </Text>
                        </Box>
                        <Box flex={0.8}>
                          <Input
                            fontSize={FONTSIZES.md}
                            readOnly
                            fontFamily={"SemiBold"}
                            value={formatedDate(data.payment_date)}
                          />
                        </Box>
                      </HStack>
                    </HStack>
                    <HStack>
                      <HStack flex={1} spacing={2} alignItems={"center"}>
                        <Box flex={0.1}>
                          <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                            Member
                          </Text>
                        </Box>
                        <Box flex={0.8}>
                          <Input
                            fontSize={FONTSIZES.md}
                            readOnly
                            fontFamily={"SemiBold"}
                            value={data.person ? data.person.name : "-"}
                          />
                        </Box>
                      </HStack>
                    </HStack>
                    <HStack>
                      <HStack flex={1} spacing={2} alignItems={"center"}>
                        <Box flex={0.1}>
                          <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                            Total Sales
                          </Text>
                        </Box>
                        <Box flex={0.8}>
                          <Input
                            fontSize={FONTSIZES.md}
                            readOnly
                            fontFamily={"SemiBold"}
                            value={data.payment_details_count}
                          />
                        </Box>
                      </HStack>
                    </HStack>
                    <HStack>
                      <HStack flex={1} spacing={2} alignItems={"center"}>
                        <Box flex={0.1}>
                          <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                            Payment Method
                          </Text>
                        </Box>
                        <Box flex={0.8}>
                          <AsyncSelect
                            chakraStyles={{
                              control: (base) => ({
                                ...base,

                                fontSize: FONTSIZES.md,
                                fontFamily: "Bold",
                              }),
                            }}
                            isDisabled={!showEdit}
                            value={data.payment_method}
                            getOptionLabel={(e) => e.name}
                            getOptionValue={(e) => e.id}
                            onChange={(e) =>
                              setdata({ ...data, payment_method: e })
                            }
                            cacheOptions={false}
                            loadOptions={loadOptionsPaymentMethod}
                            defaultOptions={false}
                          />
                        </Box>
                      </HStack>
                    </HStack>
                  </Stack>
                </HStack>
              </Box>
            )}
          </Box>

          <Box bg="white" p={5} borderRadius={5} mt={5}>
            <HStack justifyContent={"space-between"} mb={5}>
              <Box>
                <Text
                  fontSize={FONTSIZES.md}
                  fontFamily={"SemiBold"}
                  color="black"
                >
                  Detail Payment
                </Text>
              </Box>
            </HStack>
            <Box bg="teal.500" borderTopRadius={5} p={5}>
              <HStack flex={1}>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text
                      fontSize={FONTSIZES.md}
                      color={"white"}
                      fontFamily={"SemiBold"}
                    >
                      Sales No
                    </Text>
                  </Box>
                  <Box flex={0.2}>
                    <Text
                      fontSize={FONTSIZES.md}
                      color={"white"}
                      fontFamily={"SemiBold"}
                    >
                      Sales Date
                    </Text>
                  </Box>
                  <Box flex={0.3}>
                    <Text
                      fontSize={FONTSIZES.md}
                      color={"white"}
                      fontFamily={"SemiBold"}
                    >
                      Notes
                    </Text>
                  </Box>
                  <Box flex={0.2}>
                    <Text
                      fontSize={FONTSIZES.md}
                      color={"white"}
                      fontFamily={"SemiBold"}
                    >
                      Bill Amount
                    </Text>
                  </Box>
                  <Box flex={0.2}>
                    <Text
                      fontSize={FONTSIZES.md}
                      color={"white"}
                      fontFamily={"SemiBold"}
                    >
                      Payment
                    </Text>
                  </Box>
                  <Box flex={0.2}>
                    <Text
                      fontSize={FONTSIZES.md}
                      color={"white"}
                      fontFamily={"SemiBold"}
                    >
                      Remaining
                    </Text>
                  </Box>
                  <Box flex={0.1}>
                    <Text
                      fontSize={FONTSIZES.md}
                      color={"white"}
                      fontFamily={"SemiBold"}
                    ></Text>
                  </Box>
                </HStack>
              </HStack>
            </Box>
            <Stack borderWidth={1} borderBottomRadius={5}>
              {dataPayment.map((item, index) => {
                return (
                  <DataComponent
                    dataPayment={dataPayment}
                    setdataPayment={setdataPayment}
                    maxPay={maxPay}
                    showEdit={showEdit}
                    handleDeleteRow={handleDeleteRow}
                    item={item}
                    index={index}
                  />
                );
              })}

              <Stack>
                <HStack px="5" flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}></Box>
                  <Box flex={0.2}></Box>

                  <Box flex={0.3}>
                    <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"}>
                      Total
                    </Text>
                  </Box>
                  <Box flex={0.2}>
                    <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"}>
                      Rp {formattedCurrency(data.total_bill)}
                    </Text>
                  </Box>
                  <Box flex={0.2}>
                    <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"}>
                      Rp {formattedCurrency(data.total_pay)}
                    </Text>
                  </Box>
                  <Box flex={0.2}>
                    <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"}>
                      Rp {formattedCurrency(data.total_remaining)}
                    </Text>
                  </Box>
                  <Box flex={0.1}>
                    <Text
                      fontSize={FONTSIZES.md}
                      fontFamily={"SemiBold"}
                    ></Text>
                  </Box>
                </HStack>
              </Stack>
            </Stack>
            <HStack pt={4} justifyContent={"flex-end"}>
              <Button
                isDisabled={
                  !showEdit ||
                  dataPayment.some((item) => !item.pay || item.pay < 1)
                }
                colorScheme="teal"
                onClick={() => handleSubmit()}
              >
                <HStack alignItems={"center"} justifyContent={"center"}>
                  <MyIcon name={"check"} size={14} />
                  <Text
                    fontSize={FONTSIZES.md}
                    fontFamily={"SemiBold"}
                    color="white"
                  >
                    Save
                  </Text>
                </HStack>
              </Button>
            </HStack>
          </Box>

          <Modal
            isOpen={isOpen}
            onClose={onClose}
            size={["xs", "sm", "md", "lg", "xl"]}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
                  Data log sales : {data.transaction_no}
                </Text>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {data.activity_logs?.map((item, index) => {
                  return <ActivityLogs item={item} key={index} index={index} />;
                })}
              </ModalBody>
              <ModalFooter></ModalFooter>
            </ModalContent>
          </Modal>

          <Modal
            isOpen={showDelete}
            onClose={() => setshowDelete(false)}
            size={["xs", "sm", "md", "lg", "xl"]}
          >
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
                  Delete Payment Bill: {data.transaction_no}
                </Text>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
                  Are you sure?
                </Text>
              </ModalBody>

              <ModalFooter>
                <Button variant={"ghost"} mr={3} onClick={onClose}>
                  <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
                    Close
                  </Text>
                </Button>
                <Button colorScheme="red" onClick={handleDelete}>
                  <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
                    Delete
                  </Text>
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </AnimatedContainer>
  );
};
export default DetailPaymentBill;
