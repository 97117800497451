import {
  ChevronDownIcon,
  ChevronUpIcon,
  HamburgerIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  VStack,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Logo from "../../assets/images/logoWhite.jpg";
import { FONTSIZES } from "../../theme/FontSize";
import { useHttp } from "../../utils/http";
import { useSession } from "../../utils/session";
import { URL } from "../../utils/appURL";
import { clearData } from "../../utils/redux/UserDataReducer";
import { useSelector, useDispatch } from "react-redux";

const Navbar = ({ h }) => {
  const { id } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const { pathname } = useLocation();
  const [dataMenu, setdataMenu] = useState([]);
  const navigate = useNavigate();
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const pathnames = pathname.split("/").filter((x) => x);
  const { removeSession } = useSession();
  const { get, post } = useHttp();
  const userData = useSelector((state) => state.userData.value);
  const dispatch = useDispatch();

  const handleLogout = () => {
    post({
      url: URL.logoutURL,
      data: {},
      needAuth: true,
      showToast: false,
    }).then((response) => {
      removeSession({ doLogout: true }).then(() => {
        dispatch(clearData());
      });
    });
  };

  const handleNav = (item) => {
    toggleDrawer();
    navigate(item);
  };

  useEffect(() => {
    get({ url: URL.getListMenuURL, needAuth: true, showToast: false }).then(
      (response) => {
        setdataMenu(response.data.data);
      }
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReload = (item) => {
    if (pathnames.length === 1) {
      if (item.url === pathnames[0]) {
        if (!id) {
          window.location.reload();
        }
      }
    }
  };
  return (
    <Flex
      position={"relative"}
      zIndex={20}
      h={h}
      as="nav"
      align="center"
      justify="space-between"
      padding="1rem"
      bg="teal.500"
      color="white"
    >
      {isMobile ? (
        <Box>
          <Button onClick={toggleDrawer} bg="transparent">
            <HamburgerIcon color={"white"} />
          </Button>
          <Drawer isOpen={isDrawerOpen} placement="left" onClose={toggleDrawer}>
            <DrawerOverlay />
            <DrawerContent bg={"teal.500"} borderRightRadius={20}>
              <DrawerCloseButton color={"white"} />
              <DrawerHeader>
                <Button colorScheme="teal" bg="transparent">
                  <Image
                    boxSize="40px"
                    objectFit="cover"
                    rounded={"full"}
                    src={Logo}
                    alt="logo"
                  />
                </Button>
              </DrawerHeader>

              <DrawerBody>
                <Stack spacing={4}>
                  {dataMenu.map((item, index) => {
                    return (
                      <HStack key={index}>
                        {item.sub_menus.length > 0 ? (
                          <Menu>
                            {({ isOpen }) => (
                              <>
                                <MenuButton
                                  colorScheme="teal"
                                  bg="transparent"
                                  as={Button}
                                  rightIcon={
                                    isOpen ? (
                                      <ChevronUpIcon color="white" />
                                    ) : (
                                      <ChevronDownIcon color="white" />
                                    )
                                  }
                                >
                                  <VStack>
                                    <Text fontFamily={"SemiBold"} color="white">
                                      {item.title}
                                    </Text>
                                  </VStack>
                                </MenuButton>
                                <MenuList>
                                  {item.sub_menus.map((item, index) => {
                                    return (
                                      <MenuItem
                                        key={index}
                                        minH="48px"
                                        onClick={() => handleNav(item.url)}
                                      >
                                        <Text
                                          color={"black"}
                                          fontFamily={"Medium"}
                                          fontSize={FONTSIZES.md}
                                        >
                                          {item.title}
                                        </Text>
                                      </MenuItem>
                                    );
                                  })}
                                </MenuList>
                              </>
                            )}
                          </Menu>
                        ) : (
                          <Button
                            key={index}
                            colorScheme="teal"
                            bg="transparent"
                            onClick={() => handleNav(item.url)}
                          >
                            <Text fontFamily={"Semibold"}>{item.title}</Text>
                          </Button>
                        )}
                      </HStack>
                    );
                  })}
                </Stack>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </Box>
      ) : (
        <HStack spacing={1}>
          <Image
            boxSize="40px"
            objectFit="cover"
            rounded={"full"}
            src={Logo}
            alt="Logo"
          />
          {dataMenu.map((item, index) => {
            return (
              <HStack key={index}>
                {item.sub_menus.length > 0 ? (
                  <Menu>
                    {({ isOpen }) => (
                      <>
                        <MenuButton
                          colorScheme="teal"
                          bg="transparent"
                          as={Button}
                          rightIcon={
                            isOpen ? (
                              <ChevronUpIcon color="white" />
                            ) : (
                              <ChevronDownIcon color="white" />
                            )
                          }
                        >
                          <VStack>
                            <Text fontFamily={"SemiBold"} color="white">
                              {item.title}
                            </Text>
                          </VStack>
                        </MenuButton>
                        <MenuList zIndex={30}>
                          {item.sub_menus.map((item, index) => {
                            return (
                              <a href={`#/${item.url}`} key={index}>
                                <MenuItem
                                  onClick={() => handleReload(item)}
                                  key={index}
                                  minH="48px"
                                >
                                  <Text
                                    color={"black"}
                                    fontFamily={"Medium"}
                                    fontSize={FONTSIZES.md}
                                  >
                                    {item.title}
                                  </Text>
                                </MenuItem>
                              </a>
                            );
                          })}
                        </MenuList>
                      </>
                    )}
                  </Menu>
                ) : (
                  <Button
                    key={index}
                    colorScheme="teal"
                    bg="transparent"
                    onClick={() => handleNav(item.url)}
                  >
                    <Text fontFamily={"Semibold"}>{item.title}</Text>
                  </Button>
                )}
              </HStack>
            );
          })}
        </HStack>
      )}

      <Menu>
        <MenuButton
          py={8}
          bg="transparent"
          colorScheme="teal"
          as={Button}
          rightIcon={<ChevronDownIcon />}
        >
          <VStack>
            <Text
              fontSize={[12, 14, 16]}
              color={"white"}
              fontFamily={"SemiBold"}
            >
              {userData?.name}
            </Text>
            <Text fontSize={10} color={"white"} fontFamily={"Regular"}>
              {userData?.type}
            </Text>
          </VStack>
        </MenuButton>
        <MenuList>
          <MenuItem minH="48px" onClick={() => onOpen()}>
            <Text color={"black"} fontFamily={"Medium"} fontSize={FONTSIZES.md}>
              Logout
            </Text>
          </MenuItem>
        </MenuList>
      </Menu>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={["xs", "sm", "md", "lg", "xl"]}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              Logout
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              Are you sure?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button variant={"ghost"} mr={3} onClick={onClose}>
              <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
                Close
              </Text>
            </Button>
            <Button colorScheme="red" onClick={handleLogout}>
              <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
                Logout
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Navbar;
