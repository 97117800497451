import React, { useState } from "react";
import {
  Box,
  Button,
  HStack,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Text,
} from "@chakra-ui/react";
import { FONTSIZES } from "../../../theme/FontSize";
import formatedDate from "../../../utils/formatedDate";
import { formattedCurrency } from "../../../utils/formatedCurrency";
import MyIcon from "../../../component/myIcon/Index";

const DataComponent = ({
  dataPayment,
  setdataPayment,
  maxPay,
  showEdit,
  handleDeleteRow,
  item,
  index,
}) => {
  const [showDeleteRow, setshowDeleteRow] = useState(false);
  return (
    <HStack p="5" borderBottomWidth={1} flex={1} key={index}>
      <HStack flex={1} spacing={10} alignItems={"center"}>
        <Box flex={0.2}>
          <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
            {item.sales_header.transaction_no}
          </Text>
        </Box>
        <Box flex={0.2}>
          <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
            {formatedDate(item.sales_header.transaction_date)}
          </Text>
        </Box>
        <Box flex={0.3}>
          <Input
            isDisabled={!showEdit}
            fontSize={FONTSIZES.md}
            bg="white"
            onChange={(v) => {
              const newPayment = [...dataPayment];
              newPayment[index] = {
                ...newPayment[index],
                notes: v.target.value,
              };
              setdataPayment(newPayment);
            }}
            value={item.notes}
            fontFamily={"Regular"}
          />
        </Box>
        <Box flex={0.2}>
          <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
            Rp. {formattedCurrency(item.bill)}
          </Text>
        </Box>
        <Box flex={0.2}>
          <Box flex={1} alignItems={"center"}>
            <InputGroup fontSize={12} flex={1}>
              <NumberInput
                borderRadius={0}
                rounded={0}
                sx={{
                  input: {
                    bg: "white",

                    fontSize: 12,
                    fontFamily: "Regular",
                  },
                }}
                isDisabled={!showEdit}
                flex={1}
                value={formattedCurrency(item.pay) || 0}
                min={0}
                max={maxPay[index] || 0}
                onChange={(v) => {
                  const newPayment = [...dataPayment];
                  newPayment[index] = {
                    ...newPayment[index],
                    pay: v,
                  };
                  setdataPayment(newPayment);
                }}
              >
                <NumberInputField />
              </NumberInput>
            </InputGroup>
            <Text position={"absolute"} color="red.500" fontSize={10}>
              Max : {formattedCurrency(maxPay[index])}
            </Text>
          </Box>
        </Box>
        <Box flex={0.2}>
          <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
            Rp. {formattedCurrency(item.remaining)}
          </Text>
        </Box>
        <Box flex={0.1}>
          <Button
            isDisabled={dataPayment.length === 1}
            colorScheme="red"
            onClick={() => setshowDeleteRow(true)}
          >
            <HStack alignItems={"center"} justifyContent={"center"}>
              <MyIcon name={"circle-x"} size={14} />
            </HStack>
          </Button>
        </Box>
      </HStack>

      <Modal
        isOpen={showDeleteRow}
        onClose={() => setshowDeleteRow(false)}
        size={["xs", "sm", "md", "lg", "xl"]}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              Delete Payment Bill Detail: {item.id}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              Are you sure?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              variant={"ghost"}
              mr={3}
              onClick={() => setshowDeleteRow(false)}
            >
              <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
                Close
              </Text>
            </Button>
            <Button colorScheme="red" onClick={() => handleDeleteRow(item.id)}>
              <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
                Delete
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </HStack>
  );
};

export default DataComponent;
