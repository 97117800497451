import { Box, HStack, Text } from "@chakra-ui/react";
import React from "react";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import NavButton from "../../../component/navButton/Index";
import PaymentBillTable from "./PaymentBillTable";

const PaymentBill = () => {
  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={"10"}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontSize={[14, 16, 18]} fontFamily={"Semibold"} color="black">
              Payment Bill
            </Text>
          </Box>
          <NavButton nav="/payment-bill/add" title="Add Payment" />
        </HStack>

        <PaymentBillTable />
      </Box>
    </AnimatedContainer>
  );
};

export default PaymentBill;
