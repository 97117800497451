import { Box, Center, HStack, Stack, Text } from "@chakra-ui/react";
import * as React from "react";

import {
  Body,
  Cell,
  Header,
  HeaderCell,
  HeaderRow,
  Row,
  Table,
} from "@table-library/react-table-library";
import {
  DEFAULT_OPTIONS,
  getTheme,
} from "@table-library/react-table-library/chakra-ui";
import { usePagination } from "@table-library/react-table-library/pagination";
import { useTheme } from "@table-library/react-table-library/theme";
import { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import emptyLotie from "../../../assets/lotties/empty.json";
import MyLoader from "../../../component/loader/MyLoader";
import { URL } from "../../../utils/appURL";
import { useHttp } from "../../../utils/http";

const RoleTable = ({ item, label }) => {
  const { get } = useHttp();
  const [data, setData] = useState({ nodes: [] });

  const WW = window.innerWidth;

  const navigate = useNavigate();
  const chakraTheme = getTheme(DEFAULT_OPTIONS);
  const custom = useTheme({
    Table: `
        --data-table-library_grid-template-columns: ${
          WW < 770 ? "100px" : "100px"
        }  minmax(150px, 1fr);
      `,

    Row: `
        &:hover {
            background-color: #d2fbf2;
            cursor: pointer; /* Add this line to change cursor to pointer on hover */
          }`,
  });
  const theme = [chakraTheme, custom];
  const [isLoading, setisLoading] = useState(true);
  const pagination = usePagination(data, {
    state: {
      page: 0,
      size: 10,
    },
    onChange: onPaginationChange,
  });

  function onPaginationChange(action, state) {}
  setTimeout(() => {
    setisLoading(false);
  }, 700);

  const handleClick = (item) => {
    const url = `/role/${item.id}`;
    navigate(url);
  };
  const fetchData = (text = "") => {
    get({
      url: URL.getListRoleURL,
      needAuth: true,
      showToast: false,
    }).then((response) => {
      setData((prevState) => ({
        ...prevState,
        nodes: response.data.data.data,
      }));
    });
  };
  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <Stack spacing={5} direction={"row"}>
        <></>
      </Stack>
      <br />
      <Box p={1} borderWidth="1px" borderRadius="lg">
        {isLoading ? (
          <MyLoader />
        ) : data.nodes?.length > 0 ? (
          <Table
            data={data}
            theme={theme}
            layout={{ custom: true, horizontalScroll: true }}
            pagination={pagination}
          >
            {(tableList) => (
              <>
                <Header>
                  <HeaderRow>
                    <HeaderCell>
                      <Text
                        fontSize={[10, 12, 14]}
                        fontFamily={"Bold"}
                        color={"black"}
                      >
                        Id
                      </Text>
                    </HeaderCell>
                    <HeaderCell>
                      <Text
                        fontSize={[10, 12, 14]}
                        fontFamily={"Bold"}
                        color={"black"}
                      >
                        Name
                      </Text>
                    </HeaderCell>
                  </HeaderRow>
                </Header>

                <Body>
                  {tableList.map((item, index) => (
                    <Row key={item.id} item={item}>
                      <Cell onClick={() => handleClick(item)}>
                        <Text
                          fontSize={[10, 12, 14]}
                          fontFamily={"Regular"}
                          color="black"
                        >
                          {item.id}
                        </Text>
                      </Cell>
                      <Cell onClick={() => handleClick(item)}>
                        <HStack>
                          <a href={`#/role/${item.id}`}>
                            <Text
                              as={"u"}
                              fontSize={[10, 12, 14]}
                              fontFamily={"Regular"}
                              color="black"
                            >
                              {item.name}
                            </Text>
                          </a>
                        </HStack>
                      </Cell>
                    </Row>
                  ))}
                </Body>
              </>
            )}
          </Table>
        ) : (
          <Stack mt={5}>
            <Center>
              <Text fontFamily={"Semibold"}>Data Not Found</Text>
            </Center>
            <Lottie
              options={{
                animationData: emptyLotie,
              }}
              width={"20vw"}
            />
          </Stack>
        )}
      </Box>
      <br />

      <br />
    </>
  );
};

export default RoleTable;
