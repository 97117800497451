import { Box, HStack, Text } from "@chakra-ui/react";
import React from "react";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import UnitTable from "./UnitTable";

const Unit = () => {
  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={"10"}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontFamily={"Semibold"} color="black">
              Unit
            </Text>
          </Box>
        </HStack>

        <UnitTable />
      </Box>
    </AnimatedContainer>
  );
};

export default Unit;
