import { Box, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";
import { FONTSIZES } from "../../theme/FontSize";
import formatedDateTime from "../../utils/formatedDateTime";

const ActivityLogs = ({ item, index }) => {
  const [showMore, setshowMore] = useState(false);

  const RenderComponent = () => {
    return (
      <VStack
        borderWidth={1}
        p="2"
        bg={index % 2 !== 0 ? "teal.50" : "grey.50"}
        flex={1}
        key={item?.id}
        alignItems={"left"}
      >
        <Box flex={1} alignItems={"center"}>
          <Text textAlign="left" fontFamily={"Regular"} fontSize={FONTSIZES.md}>
            {formatedDateTime(item?.created_at)}
          </Text>
        </Box>
        <Box flex={1}>
          <Text
            textAlign="left"
            fontFamily={"Regular"}
            fontSize={FONTSIZES.md}
            noOfLines={showMore === true ? 0 : 1}
          >
            {item?.payload}
          </Text>
          <button onClick={() => setshowMore(!showMore)}>
            <Text
              color={"teal.500"}
              fontFamily={"Semibold"}
              fontSize={FONTSIZES.md}
            >
              {showMore === true ? "Show Less" : "Show More"}
            </Text>
          </button>
        </Box>
        <Box flex={1}>
          <Text textAlign="left" fontSize={FONTSIZES.md} fontFamily={"Regular"}>
            {item?.message}
          </Text>
        </Box>
        <Box flex={1}>
          <Text textAlign="left" fontSize={FONTSIZES.md} fontFamily={"Regular"}>
            {item?.created_by}
          </Text>
        </Box>
      </VStack>
    );
  };
  return <RenderComponent item={item} />;
};

export default ActivityLogs;
