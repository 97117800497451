import { Box, HStack, Text } from "@chakra-ui/react";
import React from "react";
import NavButton from "../../../component/navButton/Index";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import RoleTable from "./TableRole";

const Role = () => {
  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={"10"}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontFamily={"Semibold"} color="black">
              Role
            </Text>
          </Box>
          <NavButton nav="/role/add" title="Add Role" />
        </HStack>

        <RoleTable />
      </Box>
    </AnimatedContainer>
  );
};

export default Role;
