import { HStack, Text } from "@chakra-ui/react";
import { Cell, Row } from "@table-library/react-table-library";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FONTSIZES } from "../../../theme/FontSize";

const RowTable = ({ item }) => {
  const navigate = useNavigate();
  const handleClick = (item) => {
    const url = `/mitra/${item.id}`;
    navigate(url);
  };

  return (
    <Row key={item.id} item={item}>
      <Cell onClick={() => handleClick(item)}>
        <Text fontSize={FONTSIZES.md} fontFamily={"Regular"} color="black">
          {item.id}
        </Text>
      </Cell>
      <Cell onClick={() => handleClick(item)}>
        <HStack>
          <a href={`#/mitra/${item.id}`}>
            <Text
              as={"u"}
              fontSize={FONTSIZES.md}
              fontFamily={"Regular"}
              color="black"
            >
              {item.name}
            </Text>
          </a>
        </HStack>
      </Cell>
      <Cell onClick={() => handleClick(item)}>
        <Text fontSize={FONTSIZES.md} fontFamily={"Regular"} color="black">
          {item.person_access.roles[0]?.name}
        </Text>
      </Cell>
      <Cell onClick={() => handleClick(item)}>
        <HStack>
          <Text fontSize={FONTSIZES.md} fontFamily={"Regular"} color="black">
            {item.code}
          </Text>
        </HStack>
      </Cell>
      <Cell onClick={() => handleClick(item)}>
        <HStack>
          <Text fontSize={FONTSIZES.md} fontFamily={"Regular"} color="black">
            {item.store.name}
          </Text>
        </HStack>
      </Cell>

      <Cell onClick={() => handleClick(item)}>
        <Text fontSize={FONTSIZES.md} fontFamily={"Regular"} color="black">
          {item.is_active ? "Active" : "Non Active"}
        </Text>
      </Cell>
    </Row>
  );
};

export default RowTable;
