import {
  Box,
  Button,
  HStack,
  Input,
  Stack,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import MyIcon from "../../../component/myIcon/Index";
import { URL } from "../../../utils/appURL";
import { useHttp } from "../../../utils/http";
const AddUnit = () => {
  const { post } = useHttp();
  const toast = useToast();
  const [data, setdata] = useState({ name: "", category: "", code: "" });
  const [isActive, setisActive] = useState();
  const nameUnit = "";
  const isDisable = false;
  const navigate = useNavigate();

  const handleUpdate = () => {
    post({
      url: URL.getListProductUnitURL + "/store",
      data: {
        name: data.name,
        code: data.code,
        is_active: isActive,
      },
      needAuth: true,
    })
      .then((res) => {
        navigate(-1);
        toast({
          title: "Success",
          description: res.data.message,
          status: "success",
          duration: 1000,
          position: "top",
        });
      })
      .catch((err) => {
        const status = err.response?.status;
        if (status == 401) {
          navigate("/");
        }
        toast({
          title: err.response?.data.message,
          description: JSON.stringify(err.response?.data.errors),
          status: "error",
          duration: 2000,
          position: "top",
        });
      });
  };
  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={10}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontFamily={"Semibold"} color="black">
              Unit
            </Text>
          </Box>
        </HStack>
        <Box borderWidth={1} borderRadius={5} p={5}>
          <Text fontFamily={"Semibold"}>Detail Unit</Text>
          <HStack flex={1}>
            <Stack flex={3} py={4} spacing={5}>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Name</Text>
                </Box>
                <Box flex={0.7}>
                  <Input
                    value={data.name}
                    fontFamily={"Regular"}
                    defaultValue={nameUnit}
                    onChange={(e) =>
                      setdata((prevState) => ({
                        ...prevState,
                        name: e.target.value,
                      }))
                    }
                  />
                </Box>
              </HStack>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Code</Text>
                </Box>
                <Box flex={0.7}>
                  <Input
                    fontFamily={"Regular"}
                    defaultValue={data.code}
                    onChange={(e) =>
                      setdata((prevState) => ({
                        ...prevState,
                        code: e.target.value,
                      }))
                    }
                  />
                </Box>
              </HStack>

              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Active</Text>
                </Box>
                <Box flex={0.7}>
                  <Switch
                    onChange={() => setisActive(!isActive)}
                    isChecked={isActive == 1 ? true : false}
                  />
                </Box>
              </HStack>
            </Stack>
          </HStack>
        </Box>

        {isDisable !== true && (
          <HStack py={10} justifyContent={"flex-end"}>
            <Button
              onClick={handleUpdate}
              colorScheme="teal"
              minW={"100px"}
              bg={"teal.500"}
            >
              <HStack alignItems={"center"}>
                <MyIcon name="save" size="14" />
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={[10, 12, 14]}
                >
                  Save
                </Text>
              </HStack>
            </Button>
          </HStack>
        )}
      </Box>
    </AnimatedContainer>
  );
};
export default AddUnit;
