import {
  Box,
  Button,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Stack,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { AsyncSelect } from "chakra-react-select";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useNavigate, useParams } from "react-router-dom";
import ActivityLogs from "../../../component/activityLog/Index";
import MyIcon from "../../../component/myIcon/Index";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import MyLoader from "../../../component/loader/MyLoader";
import { FONTSIZES } from "../../../theme/FontSize";
import { URL } from "../../../utils/appURL";
import { useHttp } from "../../../utils/http";
import RowVoucher from "./RowVoucher";
const DetailVoucher = () => {
  const { get, put, deleting } = useHttp();
  const id = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const [dataVoucher, setdataVoucher] = useState([]);

  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendDate] = useState(new Date());

  const [showDelete, setshowDelete] = useState(false);
  const [store, setstore] = useState({ name: "All", id: null });

  const [nominal, setnominal] = useState();
  const [notes, setnotes] = useState("");

  const [type, settype] = useState("");
  const datastore = [{ name: "All", id: null }];

  const [isDeletable, setisDeletable] = useState(false);

  const [isDisable, setisDisable] = useState(true);

  const [data, setdata] = useState({ activity_logs: [], code: "" });

  const [selectedProduct, setselectedProduct] = useState({ id: 0 });

  const dataApplies = [
    { title: "All", value: "all" },
    { title: "Product", value: "product" },
    { title: "Category", value: "category" },
    { title: "Sub Category", value: "subcategory" },
  ];
  const [total_voucher, settotal_voucher] = useState(0);
  const [selectedApplies, setselectedApplies] = useState("all");

  const [selectedCategory, setselectedCategory] = useState({ id: 0 });

  const [selectedSubCategory, setselectedSubCategory] = useState({ id: 0 });
  const [isLoading, setisLoading] = useState(true);
  const [discountType, setdiscountType] = useState("");

  const fetchData = () => {
    get({
      url: URL.getListVoucherURL + `/${id.id}`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      setisDeletable(res.data.data.deletable);
      setdata(res.data.data);
      setnotes(res.data.data.notes);

      setselectedApplies(res.data.data.applies_to);
      setstartDate(res.data.data.start_date);
      setselectedProduct(res?.data?.data?.product);
      setendDate(res.data.data.expired_date);
      setnominal(res.data.data.nominal);
      setselectedCategory(res.data.data.category);
      setselectedSubCategory(res.data.data.subcategory);
      setdiscountType(res.data.data.discount_type);
      setstore(
        res.data.data.store ? res.data.data.store : { name: "All", id: null }
      );

      settype(res.data.data.type);
      setdataVoucher(res.data.data.voucher_details);
      settotal_voucher(res.data.data.total_voucher);
      setisLoading(false);
    });
  };

  const handleDelete = () => {
    deleting({
      url: URL.getListVoucherURL + `/delete/${id.id}`,
      needAuth: true,
      showToast: true,
    }).then((res) => {
      onClose();
      navigate(-1);
    });
  };
  const clickEdit = () => {
    setisDisable(!isDisable);
  };

  const fetchDataProduct = async (input = "") => {
    let allData = [];
    let currentPage = 1;
    await get({
      url:
        URL.getListProductURL +
        `?search=${input}&page=${currentPage}&with_paginate=1`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      allData = res.data.data.product.data;
    });
    return allData;
  };
  const fetchDataStore = async (input = "") => {
    let allData = datastore;
    let currentPage = 1;
    await get({
      url:
        URL.getListStoreURL +
        `?search=${input}&page=${currentPage}&with_paginate=1`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      allData = res.data.data.data;
    });
    return allData;
  };
  const fetchDataCategory = async (input = "") => {
    let allData = [];
    let currentPage = 1;
    await get({
      url:
        URL.getListProductCategoryURL + `?search=${input}&page=${currentPage}`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      allData = res.data.data.data;
    });
    return allData;
  };
  const fetchDataSubCategory = async (input = "") => {
    let allData = [];
    let currentPage = 1;
    await get({
      url:
        URL.getListProductSubCategoryURL +
        `?search=${input}&page=${currentPage}`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      allData = res.data.data.data;
    });
    return allData;
  };
  const handleSubmit = () => {
    let params = {
      store_id: store.id,
      discount_type: discountType,
      applies_to: selectedApplies,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      expired_date: moment(endDate).format("YYYY-MM-DD"),
      nominal: nominal,
      notes: notes,
      details: dataVoucher,
    };
    if (selectedApplies === "product") {
      params = { ...params, product_id: selectedProduct.id };
    } else if (selectedApplies === "category") {
      params = { ...params, category_id: selectedCategory.id };
    } else if (selectedApplies === "subcategory") {
      params = { ...params, subcategory_id: selectedSubCategory.id };
    }
    put({
      url: URL.getListVoucherURL + `/update/${id.id}`,
      needAuth: true,
      showToast: true,
      data: params,
    }).then((res) => {
      navigate(-1);
    });
  };

  const handleDiscountType = (item) => {
    setdiscountType(item.toLowerCase());
    setnominal("");
  };

  const loadOptions = (inputValue) => {
    return fetchDataProduct(inputValue);
  };
  const loadOptionsStore = (inputValue) => {
    return fetchDataStore(inputValue);
  };
  const loadOptionsCategory = (inputValue) => {
    return fetchDataCategory(inputValue);
  };
  const loadOptionsSubCategory = (inputValue) => {
    return fetchDataSubCategory(inputValue);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={10}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontFamily={"Semibold"} color="black">
              Voucher
            </Text>
          </Box>
          <HStack>
            <Button colorScheme="teal" onClick={onOpen}>
              <HStack>
                <MyIcon name={"notepad-text"} size={14} />
                <Text
                  fontSize={FONTSIZES.md}
                  fontFamily={"SemiBold"}
                  color="white"
                >
                  Show Log
                </Text>
              </HStack>
            </Button>
            <Button
              isDisabled={!isDeletable}
              colorScheme="blue"
              minW={"100px"}
              bg={isDisable ? "blue.500" : "gray.500"}
              onClick={clickEdit}
            >
              <HStack alignItems={"center"}>
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={[10, 12, 14]}
                >
                  {isDisable ? "Edit" : "Cancel"}
                </Text>
              </HStack>
            </Button>
            <Button
              isDisabled={!isDeletable}
              colorScheme="red"
              minW={"100px"}
              bg={"red.500"}
              onClick={() => setshowDelete(true)}
            >
              <HStack alignItems={"center"}>
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={[10, 12, 14]}
                >
                  Delete
                </Text>
              </HStack>
            </Button>
          </HStack>
        </HStack>
        {isLoading ? (
          <MyLoader />
        ) : (
          <Box borderWidth={1} borderRadius={5} p={5}>
            <Text fontFamily={"Semibold"}>Detail Voucher</Text>
            <HStack flex={1}>
              <Stack flex={3} py={4} spacing={5}>
                {/* <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Code</Text>
                </Box>
                <Box flex={0.7}>
                  <Input
                    onChange={(e) => setname(e.target.value)}
                    fontFamily={"Regular"}
                  />
                </Box>
              </HStack> */}
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Discount Type</Text>
                  </Box>
                  <HStack spacing={"5"} flex={0.7}>
                    {["Cash", "Percentage"].map((item, index) => {
                      return (
                        <Button
                          isDisabled={isDisable}
                          key={index}
                          colorScheme="teal"
                          onClick={() => handleDiscountType(item)}
                          bg={
                            item.toLocaleLowerCase() === discountType
                              ? "teal.500"
                              : "gray.100"
                          }
                          minW={"100px"}
                          fontSize={[10, 12, 14]}
                        >
                          <Text
                            color={
                              item.toLocaleLowerCase() === discountType
                                ? "white"
                                : "black"
                            }
                            fontFamily={"Regular"}
                          >
                            {item}
                          </Text>
                        </Button>
                      );
                    })}
                  </HStack>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Applies To</Text>
                  </Box>
                  <HStack spacing={"5"} flex={0.7}>
                    {dataApplies.map((item, index) => {
                      return (
                        <Button
                          isDisabled={isDisable}
                          key={index}
                          colorScheme="teal"
                          onClick={() => setselectedApplies(item.value)}
                          bg={
                            item.value === selectedApplies
                              ? "teal.500"
                              : "gray.100"
                          }
                          minW={"100px"}
                          fontSize={[10, 12, 14]}
                        >
                          <Text
                            color={
                              item.value === selectedApplies ? "white" : "black"
                            }
                            fontFamily={"Regular"}
                          >
                            {item.title}
                          </Text>
                        </Button>
                      );
                    })}
                  </HStack>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Store</Text>
                  </Box>
                  <Box flex={0.7}>
                    <AsyncSelect
                      isDisabled={isDisable}
                      value={store}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.id}
                      onChange={(e) => setstore(e)}
                      cacheOptions={false}
                      loadOptions={loadOptionsStore}
                      defaultOptions={false}
                    />
                  </Box>
                </HStack>
                {selectedApplies !== "all" && (
                  <HStack flex={1} spacing={10} alignItems={"center"}>
                    <Box flex={0.2}>
                      <Text>
                        {selectedApplies === "product"
                          ? "Product"
                          : selectedApplies === "category"
                          ? "Category"
                          : "Sub Category"}
                      </Text>
                    </Box>
                    <Box flex={0.7}>
                      {selectedApplies === "product" ? (
                        <AsyncSelect
                          isDisabled={isDisable}
                          key="product"
                          getOptionLabel={(e) => e.name}
                          getOptionValue={(e) => e.id}
                          onChange={(e) => setselectedProduct(e)}
                          loadOptions={loadOptions}
                          defaultOptions={false}
                          value={selectedProduct}
                        />
                      ) : selectedApplies === "category" ? (
                        <AsyncSelect
                          isDisabled={isDisable}
                          key="category"
                          getOptionLabel={(e) => e.name}
                          getOptionValue={(e) => e.id}
                          onChange={(e) => setselectedCategory(e)}
                          loadOptions={loadOptionsCategory}
                          defaultOptions={false}
                          value={selectedCategory}
                        />
                      ) : (
                        <AsyncSelect
                          isDisabled={isDisable}
                          key="sub-category"
                          getOptionLabel={(e) => e.name}
                          getOptionValue={(e) => e.id}
                          onChange={(e) => setselectedSubCategory(e)}
                          loadOptions={loadOptionsSubCategory}
                          defaultOptions={false}
                          value={selectedSubCategory}
                        />
                      )}
                    </Box>
                  </HStack>
                )}
                <HStack flex={1} spacing={10}>
                  <Box flex={0.2}>
                    <Text>Start Date</Text>
                  </Box>
                  <HStack flex={0.7}>
                    <ReactDatePicker
                      customInput={<Input flex={0.4} />}
                      disabled={isDisable}
                      value={moment(startDate).format("YYYY/MM/DD")}
                      onChange={(dates) => {
                        setstartDate(dates);
                      }}
                      startDate={startDate}
                      endDate={endDate}
                    />

                    <Box flex={0.5} justifyContent={"flex-end"}>
                      <Text textAlign={"right"}>End Date</Text>
                    </Box>

                    <ReactDatePicker
                      customInput={<Input flex={0.4} />}
                      disabled={isDisable}
                      value={moment(endDate).format("YYYY/MM/DD")}
                      onChange={(dates) => {
                        setendDate(dates);
                      }}
                      startDate={startDate}
                      endDate={endDate}
                    />
                  </HStack>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Discount</Text>
                  </Box>
                  <Box flex={0.7}>
                    <InputGroup>
                      <InputLeftAddon>
                        {discountType.toLowerCase() === "cash" ? "Rp" : "%"}
                      </InputLeftAddon>
                      <NumberInput
                        isDisabled={isDisable}
                        borderLeftRadius={0}
                        value={nominal}
                        onChange={(value) => setnominal(value)}
                        precision={0}
                        step={1}
                        min={0}
                        max={
                          discountType.toLowerCase() === "percentage"
                            ? 100
                            : undefined
                        }
                        fontFamily={"Regular"}
                      >
                        <NumberInputField
                          borderLeftRadius={0}
                          placeholder={
                            discountType.toLowerCase() === "cash" ? "Rp" : "%"
                          }
                        />
                      </NumberInput>
                    </InputGroup>
                  </Box>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Total Voucher</Text>
                  </Box>
                  <Box flex={0.7}>
                    <Input
                      isDisabled={true}
                      value={total_voucher}
                      type="number"
                      onChange={(e) => settotal_voucher(e.target.value)}
                      fontFamily={"Regular"}
                    />
                  </Box>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Notes</Text>
                  </Box>
                  <Box flex={0.7}>
                    <Input
                      isDisabled={isDisable}
                      value={notes}
                      onChange={(e) => setnotes(e.target.value)}
                      fontFamily={"Regular"}
                    />
                  </Box>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Type</Text>
                  </Box>
                  <Box flex={0.7}>
                    <Input
                      isDisabled={true}
                      value={type.toUpperCase()}
                      fontFamily={"Regular"}
                    />
                  </Box>
                </HStack>
              </Stack>
            </HStack>
            <HStack py={10} justifyContent={"flex-end"}>
              <Button
                isDisabled={isDisable}
                onClick={handleSubmit}
                colorScheme="teal"
                minW={"100px"}
                bg={"teal.500"}
              >
                <HStack alignItems={"center"}>
                  <Text
                    fontFamily={"Semibold"}
                    color="white"
                    fontSize={[10, 12, 14]}
                  >
                    Save
                  </Text>
                </HStack>
              </Button>
            </HStack>
          </Box>
        )}
      </Box>
      {isLoading ? (
        <MyLoader />
      ) : (
        <Box my="5" flex={1} bg="white" p="4" rounded={10}>
          <HStack justifyContent={"space-between"} mb={5}>
            <Box>
              <Text fontSize={FONTSIZES.md} fontFamily={"Medium"} color="black">
                Detail Voucher
              </Text>
            </Box>
          </HStack>
          <Box bg="teal.500" borderTopRadius={5} p={5}>
            <HStack flex={1}>
              <HStack flex={1} alignItems={"center"}>
                <Box flex={1}>
                  <Text
                    fontSize={FONTSIZES.md}
                    textAlign={"center"}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    Code
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    fontSize={FONTSIZES.md}
                    textAlign={"center"}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    Member
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    fontSize={FONTSIZES.md}
                    textAlign={"center"}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    Status
                  </Text>
                </Box>
                <Box flex={1}>
                  <Text
                    fontSize={FONTSIZES.md}
                    textAlign={"center"}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    Action
                  </Text>
                </Box>
              </HStack>
            </HStack>
          </Box>
          <Box borderBottomRadius={5}>
            {dataVoucher.map((item, index) => {
              return <RowVoucher item={item} key={index} />;
            })}
          </Box>
        </Box>
      )}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={["xs", "sm", "md", "lg", "xl"]}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              Data log voucher : {data.transaction_no}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {data.activity_logs.map((item, index) => {
              return <ActivityLogs item={item} key={index} index={index} />;
            })}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={showDelete}
        onClose={() => setshowDelete(false)}
        size={["xs", "sm", "md", "lg", "xl"]}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              Delete Voucher {data.transaction_no}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              Are you sure?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button variant={"ghost"} mr={3} onClick={onClose}>
              <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
                Close
              </Text>
            </Button>
            <Button colorScheme="red" onClick={handleDelete}>
              <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
                Delete
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </AnimatedContainer>
  );
};
export default DetailVoucher;
