import { Box, ChakraProvider, VStack, extendTheme } from "@chakra-ui/react";
import { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { HashRouter, Outlet, Route, Routes } from "react-router-dom";
import "../node_modules/react-simple-tree-menu/dist/main.css";
import BreadCumb from "./component/breadcumb/Index";
import Footer from "./component/footer/Index.js";
import Navbar from "./component/navbar/Index";
import NotFound from "./pages/404/index.js";
import Dashboard from "./pages/dashboard/Index";
import Login from "./pages/login/Index";
import AddCashier from "./pages/master/cashier/AddCashier.js";
import DetailCashier from "./pages/master/cashier/DetailCashier.js";
import Cashier from "./pages/master/cashier/Index.js";
import DetailCategory from "./pages/master/category/DetailCategory.js";
import Category from "./pages/master/category/Index.js";

import DetailCustomer from "./pages/master/customer/DetailCustomer.js";
import Customer from "./pages/master/customer/Index.js";

import DetailMitra from "./pages/master/mitra/DetailMitra.js";
import Mitra from "./pages/master/mitra/Index";
import AddOtherType from "./pages/master/otherType/AddOtherType.js";
import DetailOtherType from "./pages/master/otherType/DetailOtherType.js";
import OtherType from "./pages/master/otherType/Index.js";
import AddPayment from "./pages/master/paymentMethod/AddPayment.js";
import DetailPayment from "./pages/master/paymentMethod/DetailPayment.js";
import Payment from "./pages/master/paymentMethod/Index.js";
import DetailProduct from "./pages/master/product/DetailProduct";
import Product from "./pages/master/product/Index";
import AddUnit from "./pages/master/productUnit/AddProductUnit.js";
import DetailUnit from "./pages/master/productUnit/DetailUnit.js";
import Unit from "./pages/master/productUnit/Index.js";

import DetailStore from "./pages/master/store/DetailStore.js";
import {
  default as Gomo,
  default as Store,
} from "./pages/master/store/Index.js";
import DetailSubCategory from "./pages/master/subCategory/DetailSubCategory.js";
import SubCategory from "./pages/master/subCategory/Index.js.js";
import AssignPermission from "./pages/setup/assignPermission/Index.js";
import AddMenu from "./pages/setup/menu/AddMenu.js";
import DetailMenu from "./pages/setup/menu/DetailMenu.js";
import Menu from "./pages/setup/menu/Index.js";
import AddPermission from "./pages/setup/permission/AddPermission.js";
import DetailPermission from "./pages/setup/permission/DetailPermission.js";
import Permission from "./pages/setup/permission/Index.js";
import AddRole from "./pages/setup/role/AddRole.js";
import DetailRole from "./pages/setup/role/DetailRole.js";
import Role from "./pages/setup/role/Index.js";
import AddUser from "./pages/setup/user/AddUser.js";
import DetailUser from "./pages/setup/user/DetailUser.js";
import User from "./pages/setup/user/Index.js";
import DetailOther from "./pages/transaction/other/DetailOther.js";
import Other from "./pages/transaction/other/Index.js";
import ReportOther from "./pages/transaction/other/ReportOther.js";
import DetailPaymentBill from "./pages/transaction/paymentBill/DetailPaymentBill.js";
import PaymentBill from "./pages/transaction/paymentBill/Index.js";
import AddPromotion from "./pages/transaction/promotion/AddPromotion.js";
import DetailPromotion from "./pages/transaction/promotion/DetailPromotion.js";
import Discount from "./pages/transaction/promotion/Index.js";
import AddPurchase from "./pages/transaction/purchase/AddPurchase.js";
import DetailPurchase from "./pages/transaction/purchase/DetailPurchase.js";
import Purchase from "./pages/transaction/purchase/Index.js";
import ReportPurchase from "./pages/transaction/purchase/ReportPurchase.js";

import DetailSale from "./pages/transaction/sale/DetailSale.js";
import Sale from "./pages/transaction/sale/Index.js";
import ReportSales from "./pages/transaction/sale/ReportSale.js";
import AddVoucher from "./pages/transaction/voucher/AddVoucher.js";
import DetailVoucher from "./pages/transaction/voucher/DetailVoucher.js";
import Voucher from "./pages/transaction/voucher/Index.js";
import ReportPromotion from "./pages/transaction/promotion/ReportPromotion.js";
import ReportPaymentBill from "./pages/transaction/paymentBill/ReportPaymentBill.js";
import Retur from "./pages/transaction/retur/Index.js";
import ReportRetur from "./pages/transaction/retur/ReportRetur.js";
import DetailRetur from "./pages/transaction/retur/DetailRetur.js";
import AddPaymentBill from "./pages/transaction/paymentBill/AddPaymentBill.js";
import { useSession } from "./utils/session.js";
import { useHttp } from "./utils/http.js";
import { setData } from "./utils/redux/UserDataReducer.js";
import { useDispatch } from "react-redux";
import { URL } from "./utils/appURL.js";

const routes = [
  { path: "/", element: <Dashboard /> },
  { path: "master", element: <Gomo /> },
  { path: "purchase", element: <Purchase /> },
  { path: "purchase/add", element: <AddPurchase /> },
  { path: "sales", element: <Sale /> },
  { path: "sales/:id", element: <DetailSale /> },
  { path: "payment/add", element: <AddPayment /> },
  { path: "payment/:id", element: <DetailPayment /> },
  { path: "payment", element: <Payment /> },
  { path: "customer", element: <Customer /> },
  { path: "customer/:id", element: <DetailCustomer /> },

  { path: "store", element: <Store /> },
  { path: "store/:id", element: <DetailStore /> },
  { path: "voucher", element: <Voucher /> },
  { path: "voucher/:id", element: <DetailVoucher /> },
  { path: "voucher/report", element: <DetailVoucher /> },
  { path: "voucher/add", element: <AddVoucher /> },
  { path: "purchase", element: <Purchase /> },
  { path: "purchase/:id", element: <DetailPurchase /> },
  { path: "purchase/add", element: <AddPurchase /> },
  { path: "mitra", element: <Mitra /> },
  { path: "mitra/:id", element: <DetailMitra /> },
  { path: "cashier", element: <Cashier /> },
  { path: "cashier/:id", element: <DetailCashier /> },
  { path: "cashier/add", element: <AddCashier /> },
  { path: "product", element: <Product /> },
  { path: "product/:id", element: <DetailProduct /> },
  { path: "unit", element: <Unit /> },
  { path: "unit/:id", element: <DetailUnit /> },
  { path: "unit/add", element: <AddUnit /> },
  { path: "category", element: <Category /> },
  { path: "category/:id", element: <DetailCategory /> },
  { path: "subcategory/:id", element: <DetailSubCategory /> },
  { path: "subcategory", element: <SubCategory /> },
  { path: "permission", element: <Permission /> },
  { path: "permission/:id", element: <DetailPermission /> },
  { path: "permission/add", element: <AddPermission /> },
  { path: "menu", element: <Menu /> },
  { path: "menu/:id", element: <DetailMenu /> },
  { path: "menu/add", element: <AddMenu /> },
  { path: "role", element: <Role /> },
  { path: "role/:id", element: <DetailRole /> },
  { path: "role/add", element: <AddRole /> },
  { path: "user", element: <User /> },
  { path: "user/:id", element: <DetailUser /> },
  { path: "user/add", element: <AddUser /> },
  { path: "purchase/report", element: <ReportPurchase /> },
  { path: "sales/report", element: <ReportSales /> },

  { path: "stock-adjustment", element: <Other /> },
  { path: "stock-adjustment/report", element: <ReportOther /> },
  { path: "stock-adjustment/:id", element: <DetailOther /> },
  { path: "other-type", element: <OtherType /> },
  { path: "other-type/add", element: <AddOtherType /> },
  { path: "other-type/:id", element: <DetailOtherType /> },
  { path: "assign-permission", element: <AssignPermission /> },
  { path: "promotion", element: <Discount /> },
  { path: "promotion/add", element: <AddPromotion /> },
  { path: "promotion/report", element: <ReportPromotion /> },
  { path: "promotion/:id", element: <DetailPromotion /> },
  { path: "payment-bill", element: <PaymentBill /> },
  { path: "payment-bill/report", element: <ReportPaymentBill /> },
  { path: "payment-bill/add", element: <AddPaymentBill /> },
  { path: "payment-bill/:id", element: <DetailPaymentBill /> },
  { path: "retur", element: <Retur /> },
  { path: "retur/report", element: <ReportRetur /> },
  { path: "retur/:id", element: <DetailRetur /> },
];

const App = () => {
  const activeLabelStyles = {
    transform: "scale(0.85) translateY(-24px)",
  };
  const theme = extendTheme({
    components: {
      Form: {
        variants: {
          floating: {
            container: {
              _focusWithin: {
                label: {
                  ...activeLabelStyles,
                },
              },
              "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
                {
                  ...activeLabelStyles,
                },
              label: {
                top: 0,
                left: 0,
                zIndex: 2,
                position: "absolute",
                backgroundColor: "white",
                pointerEvents: "none",
                mx: 3,
                px: 1,
                my: 2,
                transformOrigin: "left top",
              },
            },
          },
        },
      },
    },
  });

  return (
    <ChakraProvider theme={theme}>
      <HashRouter basename="/">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Layout />}>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </HashRouter>
    </ChakraProvider>
  );
};

function Layout() {
  const dispatch = useDispatch();
  const { checkSession } = useSession();
  const { get } = useHttp();
  useEffect(() => {
    checkSession({ doLogout: true })
      .then((response) => {
        get({
          url: URL.getDataSessionUserURL,
          needAuth: true,
          showToast: false,
        })
          .then((response) => {
            dispatch(setData(response.data.data));
          })
          .catch((err) => {});
      })
      .catch((err) => {});
  }, []);
  return (
    <Box
      minH="100vh"
      w={"100%"}
      bg="#f4f6f8"
      display="flex"
      flexDirection="column"
    >
      <Navbar h={"4rem"} />
      <VStack
        w="100%"
        justifyContent={"space-between"}
        p={"1rem"}
        alignItems={"flex-start"}
        flex={1}
      >
        <VStack alignItems={"flex-start"} spacing={5} flex={1} w={"100%"}>
          <BreadCumb />
          <Box rounded={10} flex={1} w="100%">
            <Outlet />
          </Box>
        </VStack>
        <Footer />
      </VStack>
    </Box>
  );
}

export default App;
