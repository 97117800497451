import { Box, Button, HStack, Input, Stack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import NavButton from "../../../component/navButton/Index";
import { URL } from "../../../utils/appURL";
import { useHttp } from "../../../utils/http";

const AddPermission = () => {
  const { post } = useHttp();
  const [name, setname] = useState("");
  const navigate = useNavigate();

  const handleAdd = () => {
    post({
      url: URL.getListPermissionURL + `/store`,
      data: {
        name: name,
      },
      needAuth: true,
      showToast: true,
    }).then((res) => {
      navigate(-1);
    });
  };
  return (
    <Box flex={1} bg="white" p="4" rounded={10}>
      <HStack justifyContent={"space-between"} mb={5}>
        <Box>
          <Text fontFamily={"Semibold"} color="black">
            Permission
          </Text>
        </Box>
        <NavButton nav={-1} title="Back" />
      </HStack>
      <Box borderWidth={1} borderRadius={5} p={5}>
        <Text fontFamily={"Semibold"}>Add Permission</Text>
        <Stack spacing={8} maxW={"lg"} py={4}>
          <HStack spacing={4} alignItems={"center"}>
            <Box flex={0.2}>
              <Text>Name</Text>
            </Box>
            <Box flex={1}>
              <Input
                value={name}
                onChange={(e) => setname(e.target.value)}
                fontFamily={"Medium"}
              />
            </Box>
          </HStack>
        </Stack>
      </Box>
      <HStack spacing={4} py={4} justifyContent={"flex-end"}>
        <Button
          isDisabled={name.length < 3}
          onClick={handleAdd}
          colorScheme="teal"
          minW="100px"
        >
          <Text fontFamily={"Medium"} color="white" fontSize={[10, 12, 14]}>
            Save
          </Text>
        </Button>
      </HStack>
    </Box>
  );
};
export default AddPermission;
