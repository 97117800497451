import { Box, HStack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import ReturTable from "./ReturTable";

const Retur = () => {
  const [data, setData] = useState([]);

  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={"10"}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontFamily={"Semibold"} color="black">
              Retur
            </Text>
          </Box>
        </HStack>

        <ReturTable />
      </Box>
    </AnimatedContainer>
  );
};

export default Retur;
