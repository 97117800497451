import { Box, Button, HStack, Text } from "@chakra-ui/react";
import React from "react";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";

import MyIcon from "../../../component/myIcon/Index";
import { FONTSIZES } from "../../../theme/FontSize";
import DiscountTable from "./PromotionTable";

const Discount = () => {
  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={"10"}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontFamily={"Semibold"} color="black">
              Promotion
            </Text>
          </Box>
          <a href="#/promotion/add">
            <Button colorScheme="teal" minW={"100px"} bg={"teal.500"}>
              <HStack alignItems={"center"}>
                <MyIcon name="plus" size="14" />
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={FONTSIZES.md}
                >
                  Add New
                </Text>
              </HStack>
            </Button>
          </a>
        </HStack>

        <DiscountTable />
      </Box>
    </AnimatedContainer>
  );
};

export default Discount;
