import { Box, HStack, Text } from "@chakra-ui/react";
import React from "react";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import SaleTable from "./SaleTable";

const Sale = () => {
  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={"10"}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontSize={[14, 16, 18]} fontFamily={"Semibold"} color="black">
              Sales
            </Text>
          </Box>
        </HStack>

        <SaleTable />
      </Box>
    </AnimatedContainer>
  );
};

export default Sale;
