import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { FONTSIZES } from "../../../theme/FontSize";
import { URL } from "../../../utils/appURL";
import { useHttp } from "../../../utils/http";

const Rowstore = ({ item, key, index }) => {
  const { deleting } = useHttp();
  const navigate = useNavigate();

  const { isOpen, onClose } = useDisclosure();
  const toast = useToast();
  const handleDelete = () => {
    deleting({
      url: URL.getListVoucherURL + `/delete-row/${item.id}`,
      needAuth: true,
    })
      .then((res) => {
        onClose();
        navigate(-1);
        toast({
          title: "Success",
          description: res.data.message,
          status: "success",
          duration: 1000,
          position: "top",
        });
      })
      .catch((err) => {
        const status = err.response?.status;
        if (status == 401) {
          navigate("/");
        }
        toast({
          title: err.response?.data.message,
          description: err.response.data.message,
          status: "error",
          duration: 1000,
          position: "top",
        });
      });
  };

  return (
    <>
      <HStack
        onClick={() => {}}
        p="2"
        bg={index % 2 !== 0 ? "teal.50" : "grey.50"}
        flex={1}
        alignItems={"center"}
      >
        <Box flex={1} alignItems={"center"}>
          <Text
            textAlign="center"
            fontFamily={"Regular"}
            fontSize={FONTSIZES.md}
          >
            {item.name}
          </Text>
        </Box>

        <Box flex={1}>
          <Text
            textAlign="center"
            fontSize={FONTSIZES.md}
            fontFamily={"Regular"}
          >
            {item.code}
          </Text>
        </Box>
        <Box flex={1}>
          <Text
            textAlign="center"
            fontSize={FONTSIZES.md}
            fontFamily={"Regular"}
          >
            {item.person_access.roles[0]?.name}
          </Text>
        </Box>
      </HStack>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={["xs", "sm", "md", "lg", "xl"]}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
              Delete Voucher {item.code}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
              Are you sure?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button variant={"ghost"} mr={3} onClick={onClose}>
              <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
                Close
              </Text>
            </Button>
            <Button colorScheme="red" onClick={handleDelete}>
              <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
                Delete
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Rowstore;
